.totalBlogs_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.totalBlogs_heading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 42vw;
}
.totalBlogs_heading h1 {
  background: linear-gradient(90deg, #081acf 0%, #f7714e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2vw;
  font-family: "poppins";
}
.blog_creation_icon button{
    margin-left: 35vw;
    margin-top: 1vw;
    font-size: 1.5vw;
    padding:0.2vw 0.5vw;
    color: rgba(255, 255, 255, 1);
    border-radius: 0.5vw;
    border: none;
    background: linear-gradient(90deg, #f23737 0%, #061ad0 100%);
    font-family: inter;
    width: 10vw;
    height: 3vw;
    cursor: pointer;

  }
  .blog_creation_icon button:hover{
    filter: blur(1px);
  }
.SingleBlog_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  flex-wrap: wrap;
}

.Single_Blog_Container {
  display: flex;
  flex-direction: column;
  width: 25vw;
}
.totalBlog_Image {
  width: 24.599vw;
  height: 17.56vw;
  border-radius: 1.54vw;
  object-fit: cover;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.totalBlog_Title {
  font-family: inter;
  color: rgba(0, 0, 0, 1);
  font-size: 1.8vw;
  height: 4.3vw;
  font-weight: 600;
  margin-bottom: -1vw;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.totalBlog_content {
  font-size: 1.3vw;
  font-family: inter;
  color: rgba(81, 80, 80, 1);
  width: 98%;
  height: 9.6vw;
  font-weight: 500;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.totalBlog_content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.totalBlogs_Persons_Info {
  display: flex;
  align-items: center;
  gap: 1vw;
  margin-top: 0vw;
}

.totalBlogs_Persons_Info p {
  color: rgba(0, 0, 0, 1);
  font-size: 1vw;
  font-family: inter;
  font-weight: 500;
}
.totalBlogs_persons1,
.totalBlogs_persons2 {
  display: flex;
  align-items: center;
  gap: 0.3vw;
}

.totalBlogs_persons1 img {
  width: 1.7vw;
}
.totalBlogs_persons2 img {
  width: 0.3vw;
}
.shader_wrap {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.shader_wrap:hover .totalBlog_Image {
  filter: blur(0.2vw);
  border-radius: 1.5vw;
}

.delete_btn {
  position: absolute;
  top: 0.8vw;
  right: 0.8vw;
  font-size: 1.3vw;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  border-radius: 50%;
  display: none;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.shader_wrap:hover .delete_btn {
  display: block;
  opacity: 1;
}
.pagination_container{
margin-top: 1vw;
}
@media only screen and (max-width: 780px) {
  .SingleBlog_Container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 2vw;
  }
  .Single_Blog_Container {
    display: flex;
    flex-direction: column;
    width: 70vw;
  }
  .Single_Blog_Container span {
    font-size: 4vw;
    margin-left: 5vw;
  }
  .Single_Blog_Container p {
    font-size: 4vw;
  }
  .totalBlog_Image {
    width: 70vw;
    height: 45vw;
    border-radius: 4.5vw;
  }

  .totalBlog_Title {
    font-family: inter;
    color: rgba(0, 0, 0, 1);
    font-size: 4.5vw !important;
    height: 5vw;
    font-weight: 600;
    margin-bottom: -1vw;
  }

  .totalBlog_content {
    font-weight: 500;
    font-size: 3vw !important;
    color: rgba(81, 80, 80, 1);
    height: 10vw;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-top: 9vw;
    margin-left: 6vw;
  }

  .totalBlogs_Persons_Info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 4vw;
    position: relative;
    bottom: 2vw;
  }
  .totalBlogs_Persons_Info p {
    color: rgba(0, 0, 0, 1);
    font-size: 2.5vw !important;
    font-weight: 500;
    font-family: inter;
  }
  .totalBlogs_persons1 img {
    width: 4vw;
  }
  .totalBlogs_persons2 img {
    width: 1vw;
  }
}
