@font-face {
  font-family: "Helvetica Now Display Regular";
  src: local("Helvetica Now Display Regular"),
    local("Helvetica-Now-Display-Regular"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display Medium";
  src: local("Helvetica Now Display Medium"),
    local("Helvetica-Now-Display-Medium"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display Extra Bold";
  src: local("Helvetica Now Display Extra Bold"),
    local("Helvetica-Now-Display-Extra-Bold"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.ttf")
      format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display Thin";
  src: local("Helvetica Now Display Thin"), local("Helvetica-Now-Display-Thin"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
}

.Main_Blog_Bg {
  display: flex;
  justify-content: center;
  background: linear-gradient(
    180deg,
    #0b1465 0%,
    #8f4f5f 65.22%,
    #ffffff 85.09%
  );
  padding: 6vw 0vw 10vw 0vw;
  margin-top: -36vw;
}

/* Circles   */
.CircleBlog1 {
  position: relative;
  width: 15vw;
  height: 15vw;
  border-radius: 100%;
  background: linear-gradient(180deg, #f7714e 0%, #3343d6 100%);
  top: 59vw;
  left: 83.8vw;
  overflow: hidden;
}

.CircleBlog2 {
  position: relative;
  width: 15vw;
  height: 15vw;
  border-radius: 100%;
  background: linear-gradient(180deg, #f7714e 0%, #3343d6 100%);
  top: -1vw;
  left: 0vw;
}

/* Blogs  */

/* Top Blogs  */

.Blog_Main {
  position: relative;
  border-radius: 20px;
  width: 85vw;
  padding: 0vw 0vw 5vw 0vw;
  background: rgba(255, 255, 255, 1);
  margin-top: 4vw;
}

.Blog_Main div > h3 {
  font-size: 2.2vw;
  font-family: inter;
  font-weight: 600;
  margin-left: 4vw;
  padding-top: 30px;
}

.Top_Blogs_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.Blog_Container {
  display: flex;
  justify-content: space-around;
  width: 90%;
  gap: 5vw;
}

.sub_Blog_Container1 {
  display: flex;
  gap: 2.4vw;
}

.sub_Blog_Container1 > img {
  width: 28vw;
}

.Top_Blog_Content h1 {
  font-size: 3vw;
  font-family: "Helvetica Now Display Regular";
  /* font-weight: 600; */
  color: rgba(0, 0, 0, 1);
}

.Top_Blog_Content p {
  font-size: 1.2vw;
  font-family: inter;
  font-weight: 500;
  color: rgba(81, 80, 80, 1);
}

.Blog_person_Info {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.Blog_person_Info p {
  /* font-weight: 600; */
  font-family: "Helvetica Now Display Extra Bold";
  color: rgba(0, 0, 0, 1);
  font-size: 1vw;
}

.person1,
.person2 {
  display: flex;
  align-items: center;
  gap: 0.3vw;
}

.person1 img {
  width: 1.2vw;
}
.person2 > p {
  font-weight: 500;
  font-family: inter;
}
.person1 > p {
  font-weight: 500;
  font-family: inter;
}

.person2 img {
  width: 0.2vw;
}

.Top_Blog_Content > button {
  position: relative;
  width: 9vw;
  height: 3.5vw;
  font-family: "Helvetica Now Display Medium";
  font-size: 1.4vw;
  /* font-weight: 600; */
  border-radius: 1.1vw;
  border: none;

  background: white;
  /* Background color of the button */
  /* z-index: 1; */
  /* Ensure the button content is above the pseudo-element */
  overflow: hidden;
  /* Ensure the pseudo-element is contained within the button's rounded corners */
}

.Top_Blog_Content > button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 1.1vw;
  padding: 2px;
  /* Space for the gradient border */
  background: linear-gradient(180deg, #081acf 0%, #f7714e 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  /* Allow button interactions */
}

.sub_Blog_Container2 > section > h2 {
  font-size: 2vw;
  font-family: inter;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}

.sub_Blog_Container2 > section > p {
  font-size: 1.2vw;
  font-weight: 500;
  line-height: 1.313vw;

  font-family: "Helvetica Now Display Regular";
  color: rgba(81, 80, 80, 1);
}

/* Recent_Blogs_Container  */

.Recent_Blogs_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  gap: 2vw;
  /* border: 2px solid black; */
  flex-wrap: wrap;
}

.Single_Blog_Container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 25vw;
  /* height: 41vw; */
  /* gap: 1vw; */
  /* margin-bottom: -10vw; */
}

.BlogImage {
  width: 24.599vw;
  height: 17.56vw;
  border-radius: 1.54vw;

  object-fit: cover;
  /* border: 2px solid; */
}

.BlogTitle {
  /* width: 100%; */
  font-family: inter;
  color: rgba(0, 0, 0, 1);
  font-size: 1.8vw;
  height: 4.3vw;
  /* border: 2px solid; */
  font-weight: 600;
  margin-bottom: -1vw;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.BlogContent {
  font-size: 1.3vw;
  font-family: inter;
  color: rgba(81, 80, 80, 1);
  /* border: 2px solid; */
  width: 85%;
  height: 9.6vw;
  font-weight: 500;
  overflow: hidden;
  overflow-y: scroll;
}

.BlogContent {
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.BlogContent::-webkit-scrollbar {
  width: 0px;
  /* For Chrome, Safari, and Opera */
  height: 0px;
  /* For horizontal scrollbars */
}

.ViewMoreBlogsBTN {
  font-size: 1.5vw;
  padding: 0.5vw;
  color: rgba(255, 255, 255, 1);
  border-radius: 0.5vw;
  border: none;
  background: linear-gradient(90deg, #f23737 0%, #061ad0 100%);
  font-family: inter;
  width: 15vw;
  height: 5vw;
  cursor: pointer;
}

.Blog_Persons_Info {
  display: flex;
  align-items: center;
  gap: 1vw;
  position: relative;
  bottom: 2vw;
}

.Blog_Persons_Info p {
  /* font-weight: 600; */
  color: rgba(0, 0, 0, 1);
  font-size: 1vw;
  font-family: inter;
  font-weight: 500;
}

.persons1,
.persons2 {
  display: flex;
  align-items: center;
  gap: 0.3vw;
}

.persons1 img {
  width: 1.7vw;
}

.persons2 img {
  width: 0.3vw;
}

.Main_Btn {
  font-size: 1.6vw;
  padding: 1.2vw;
  color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border: none;
  background: linear-gradient(90deg, #f23737 0%, #061ad0 100%);
  font-family: "Helvetica Now Display Medium";
  cursor: pointer;
}

.Main_Button {
  display: flex;
  justify-content: center;
  margin-top: 3vw;
}

.Blog_Footer {
  margin-top: -5vw;
}

.application-modal textarea {
  padding: 10px;
  font-family: "Helvetica Now Display Regular";
}
.create-blog-title {
  font-size: 25px;
}

@media only screen and (max-width: 780px) {
  .Main_Blog_Bg {
    margin-top: -19vw;
  }

  .CircleBlog2 {
    top: 30vw;
  }

  .CircleBlog1 {
    top: 120vw;
  }

  .Blog_Main div > h3 {
    margin-left: 30vw;
    padding-top: 30px;
    font-size: 6vw;
  }

  .Blog_Container {
    display: flex;
    justify-content: center;
    width: 90%;
    gap: 5vw;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }

  .sub_Blog_Container1 {
    display: flex;
    gap: 1.5vw;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .sub_Blog_Container1 > img {
    width: 55vw;
  }

  .Top_Blog_Content h1 {
    font-size: 4vw;
    margin-left: 10vw;
    font-family: inter;
  }

  .Top_Blog_Content p {
    font-size: 3vw;
    /* width: 60vw; */
  }

  .Blog_person_Info {
    display: flex;
    align-items: center;
    gap: 1vw;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  .person1 > p {
    font-weight: 500;
  }
  .person1 > img {
    width: 5vw;
  }
  .Top_Blog_Content > button {
    width: 25vw;
    height: 9vw;
    font-size: 3vw;
    margin-left: 25vw;
  }

  .sub_Blog_Container2 > section > h2 {
    font-size: 4.5vw;
  }

  .sub_Blog_Container2 > section > p {
    font-size: 3vw;
  }

  .sub_Blog_Container2 {
    display: flex;
    gap: 15vw;
    /* align-items: center; */
    justify-content: space-between;
    justify-items: center;
  }


  .Single_Blog_Container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 25vw;
    /* height: 41vw; */
    /* gap: 1vw; */
    /* margin-bottom: -10vw; */
  }

  .BlogImage {
    width: 70vw;
    height: 45vw;
    border-radius: 4.5vw;
    /* border: 2px solid; */
  }

  .BlogTitle {
    /* width: 100%; */
    font-family: inter;
    color: rgba(0, 0, 0, 1);
    font-size: 4.5vw !important;
    height: 5vw;
    /* border: 2px solid; */
    font-weight: 600;
    margin-bottom: -1vw;
  }

  .BlogContent {
    font-weight: 500;
    font-size: 3vw !important;
    color: rgba(81, 80, 80, 1);
    /* border: 2px solid; */
    /* width: 85%; */
    height: 10vw;
    overflow: hidden;
    overflow-y: scroll;
  }

  .BlogContent {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    font-size: 1vw;
    /* For Internet Explorer and Edge */
    margin-top: 9vw;
    margin-left: 6vw;
  }

  .BlogContent::-webkit-scrollbar {
    width: 0px;
    /* For Chrome, Safari, and Opera */
    height: 0px;
    /* For horizontal scrollbars */
  }

  .ViewMoreBlogsBTN {
    font-size: 3.2vw;
    padding: 0.5vw;
    color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    border: none;
    background: linear-gradient(90deg, #f23737 0%, #061ad0 100%);
    font-family: "Helvetica Now Display Medium";
    cursor: pointer;
    width: 20vw;
    height: 8vw;
  }

  .Blog_Persons_Info {
    display: flex;
    align-items: center;
    gap: 4vw;
    position: relative;
    bottom: 2vw;
  }

  .Blog_Persons_Info p {
    /* font-weight: 600; */
    color: rgba(0, 0, 0, 1);
    font-size: 2.5vw !important;
    font-weight: 500;
    font-family: inter;
  }

  .Single_Blog_Container {
    width: 70vw;
  }

  .Single_Blog_Container span {
    font-size: 4vw;
    margin-left: 5vw;
  }

  .Single_Blog_Container p {
    font-size: 4vw;
  }

  .Blog_Persons_Info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .Main_Btn {
    font-size: 3.5vw;
    padding: 2vw;
  }
  .persons1 img {
    width: 4vw;
  }
  .persons2 img {
    width: 1vw;
  }
}

/* .application-modal-content {
    background-image: url("../../Assets/Home/Rectangle_76-removebg-preview.png");
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    margin: 0 auto;
  } */

@media (max-width: 600px) {
  .create-blog-title {
    font-size: 22px;
    padding: 6px 10px;
  }
  .application-modal-content {
    width: 95vw;
    max-width: 95%;
    padding: 15px;
  }
}

@media (max-width: 800px) {
  .create-blog-title {
    font-size: 24px;
    padding: 6px 12px;
  }
  .application-modal-content {
    width: 90vw;
    max-width: 80%;
    padding: 18px;
  }
}

@media (max-width: 400px) {
  .create-blog-title {
    font-size: 16px;
    padding: 6px 8px;
  }
  .application-modal-content {
    width: 90vw;
    max-width: 85%;
    padding: 12px;
  }
}
