@font-face {
  font-family: "Helvetica Now Display Regular";
  src: local("Helvetica Now Display Regular"),
    local("Helvetica-Now-Display-Regular"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff2") format("woff2"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff") format("woff"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display Medium";
  src: local("Helvetica Now Display Medium"),
    local("Helvetica-Now-Display-Medium"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff2") format("woff2"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff") format("woff"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display Extra Bold";
  src: local("Helvetica Now Display Extra Bold"),
    local("Helvetica-Now-Display-Extra-Bold"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff2") format("woff2"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff") format("woff"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display Thin";
  src: local("Helvetica Now Display Thin"), local("Helvetica-Now-Display-Thin"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff2") format("woff2"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff") format("woff"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

.Home_Main {
  position: relative;
}

.MH_Nav_Main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Nav_Bar {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #010724;
  padding: 1.5vw 10vw 1vw 10vw;
  box-shadow: 2px 2px 5px;
  gap: 31.5vw;
  backdrop-filter: blur(2px);
}

.Nav_Bar_hide {
  position: fixed;
  top: 0vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 31.5vw;
  z-index: 20000;
  /* background-color: #010724; */
  padding: 1.5vw 10vw 1vw 10vw;
/*   box-shadow: 2px 2px 5px; */

}

.Nav_Bar h1 {
  color: aliceblue;
}

.Nav_Bar img {
  width: 10vw;
}
.Nav_Bar_hide img {
  width: 10vw;
}

.Nav_Links {
  display: flex;
  justify-content: space-between;
  gap: 4vw;
}

.Nav_links li:focus {
  color: rgb(131, 130, 125);
  transform: scale(1.5);
}

.Nav_Links li {
  list-style: none;
  color: #dfe3fb;
  font-family: "inter";
  font-size: 1.2vw;
  font-weight: 600;
}

.who-we-are-home {
  width: 8vw;
}

.what_we_do_home {
  width: 8vw;
}

.explore-more {
  /* background: linear-gradient(180deg, #f7714e 0%, #3343d6 100%); */
  /* border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px; */
  background: none;
  border: none;
  color: #FFFFFF;
  font-size: 2vw;
  font-family: "inter";
  border-bottom: 0.2vw solid white;
}

/* Logo Conatiner  */

/* 4th Image  */

.Image_Content {
  /* position: relative; */
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Globe_div {
  position: relative;
  /* border: 3px solid white; */
  border-radius: 50%;
  width: 45vw;
  height: 45vw;
  overflow: hidden;
  /* Ensures the image stays within the circular container */
  margin-top: 3vw;
  padding: 0;
}

#GlobeImage {
  position: absolute;
  object-fit: cover;
  width: 100%;
  /* Ensures the image covers the entire container */
  height: 100%;
  /* Ensures the image covers the entire container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* transition: transform 1s linear; */
}

.Globe_div:hover #GlobeImage {
  animation: rotateImage 3s linear infinite;
  cursor: pointer;
}

@keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Content_Img {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
  position: absolute;
  top: 12vw;
  left: 0;
  right: 0;
  bottom: 0;
}

.Content_Img h1 {
  width: 32vw;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-size: 3.5vw;
  position: relative;
  left: 0vw;
  text-align: center;
  font-weight: 500;
}

/* 1st Image  */

.Image1_Content_Container {
  position: relative;
  width: 100%;
  margin-top: 6vw;
}

.Image1_Content_Container img {
  width: 100%;
  height: 42vw;
}

.Content_img1 {
  position: absolute;
  top: 12vw;
}

.Content_img1 h1 {
  width: 37vw;
  /* I am using (nbsp) choose that when Applay media-Query */
  font-family: "poppins", sans-serif;
  color: #ffffff;
  font-size: 6vw;
  position: relative;
  left: 10vw;
  top: -2vw;
}

.VerticalLine {
  border: 3px solid white;
  transform: rotate(90deg);
  width: 12vw;
  position: relative;
  top: 9.5vw;
}

/* 2nd Image  */

.Image2_Content_Container {
  position: relative;
  width: 100%;
  margin-top: 6vw;
}

.Image2_Content_Container img {
  width: 100%;
  height: 42vw;
}

.Content_img2 {
  position: absolute;
  top: 7vw;
}

.Content_img2 h1 {
  width: 54vw;
  /* I am using (nbsp) choose that when Applay media-Query */
  font-family: "poppins", sans-serif;
  color: #ffffff;
  font-size: 5vw;
  position: relative;
  left: 5vw;
}

.Content_img2 p {
  width: 35vw;
  font-family: "inter";
  color: #ffffff;
  font-size: 1.5vw;
  position: relative;
  left: 5vw;
}

.Content_img2 button {
  background-color: rgba(54, 70, 218, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 0.9vw;
  font-size: 1.5vw;
  /* font-weight: 600; */
  font-family: "inter";
  border-radius: 5px;
  margin-top: 2vw;
  margin-left: 5vw;
  cursor: pointer;
}

/* 3rd Image  */
.Image3_Content_Container {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  /* box-shadow: 2px 2px 2px; */
}

.Image3_Content_Container img {
  width: 100%;
  height: 48vw;
}

.Content_img3 {
  position: absolute;
  top: 13vw;
}

.Content_img3 h1 {
  width: 54vw;
  font-family: "Helvetica Now Display Medium";
  color: rgba(0, 0, 0, 1);
  font-size: 5vw;
}

.Content_img3 p {
  width: 35vw;
  font-family: "Helvetica Now Display Medium";
  color: rgba(0, 0, 0, 1);
  font-size: 1.5vw;
  position: relative;
  left: 10vw;
  font-weight: 500;
}

.Content_img3 button {
  background: linear-gradient(90deg, #f23737 0%, #061ad0 100%);
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 0.9vw;
  font-size: 1.5vw;
  /* font-weight: 600; */
  font-family: "Helvetica Now Display Medium";
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1vw;
}

.Content_Img button {
  /* width: 10vw; */
  padding: 1.2vw 4vw;
  background: linear-gradient(to right, #f23737, #061ad0);
  font-size: 1.3vw;
  color: #ffffff;
  font-family: "inter";
  font-weight: 500;
  border: none;
  border-radius: 0.3vw;
  opacity: 0.8;
  cursor: pointer;
}

/* Text Content  */

.Text_Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3vw 0vw 2vw 0vw;
}

.Text_Content div {
  text-align: center;
}

.Text_Content h1 {
  font-family: "poppins", sans-serif;
  /* font-family: "Helvetica Now Display Medium"; */
  color: #010724;
  font-weight: 600;
  font-size: 3.5vw;
  width: 45vw;
  line-height: 4.5vw;
}

.Text_Content p {
  font-family: "Helvetica Now Display Extra Bold";
  /* font-weight: 600; */
  font-size: 1.6vw;
  background: linear-gradient(to right, #f7714e, #528eff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  bottom: 4vw;
  margin-top: 3vw;
}

/* Cards Container  */

.Services-Container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
  padding: 2vw 0vw 5vw 0vw;
  background: linear-gradient(180deg, #0B1465 1%, #DE725B 150.22%);
}

.Services-Container h1 {
  font-family: "poppins", sans-serif;
  font-weight: 500;
  font-size: 3vw;
  color: #ffffff;
}

/* .Services-Container h3 {
  font-size: 1.6vw;
  color: #ffffff;
  font-family: "Helvetica Now Display Medium";
  padding: 0.5vw 1vw 0.5vw 1vw;

  &:hover {
    cursor: pointer;
  }
} */
/* .traingles_div {
  position: relative;
} */

.Services-Container .Images11 {
  position: absolute;
  bottom: 6vw;
  left: 10vw;
  width: 9vw;
  height: 9vw;
  border-radius: 50%;
  background: linear-gradient(180deg, #F7714E 0%, #3343D6 100%);
}

.triangle_canvas {
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-left: 24vw solid #433277;
  border-top: 24vw solid transparent;
}

.Cards_Conatiner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3vw;
}

.Single_Card {
  position: relative;
  height: 25vw;
  width: 21vw;
  border-radius: 1.5vw;
  background-color: #ffffff;
  box-shadow: 0.2vw 0.2vw 0.2vw 0px #00000040;
  z-index: 100;
}

.Single_Card:hover {
  transform: scale(1.05);
  transition: 0.2s linear;
  box-shadow: 0.5vw 0.5vw 0.5vw 0px #00000040;
}
.title_div_wrapper{
  /* position: absolute;
  display: flex;
  justify-content: center;
  align-items: center; */
}
.Single_Card img {
  position: absolute;
  top: 1vw;
  right: 1vw;
  width: 6vw;
  height: 6vw;
}

.Single_Card h2 {
  position: absolute;
  top: 7vw;
  left: 2vw;
  width: 14vw;
  font-family: "poppins", sans-serif;
  font-weight: 600;
  font-size: 2vw;
  color: #000000;
  margin-top: 0vw;
}

.Single_Card p {
  position: absolute;
  top: 14vw;
  left: 2vw;
  width: 16vw;
  /* text-align: justify; */
  font-family: "inter";
  font-weight: 500;
  font-size: 1.2vw;
  color: rgba(82, 79, 79, 1);
  margin-top: -1vw;
}

.Single_Card div {
  position: absolute;
  bottom: 1vw;
  right: 1vw;
  font-size: 2vw;
}

/* Team_Container  */

.Main_Team_Container {
  display: flex;
  justify-content: center;
  /* margin-top: 7vw; */
  margin-bottom: -3vw;
  padding: 7vw 0vw 9vw 0vw;
}

.Team_Container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 80vw;
  gap: 5vw;
}

.Team_Content {
  /* height: 60vw; */
  /* overflow: hidden; */
}

.who_home_hr{
  margin-top: 2vw;
  margin-bottom: 4vw;
  border: 0.1vw solid black;
  width: 28vw;
}
.Team_Content p {
  font-family: "inter";
  font-size: 1.5vw;
  margin-top: -2vw;
  font-weight: 600;
}

.Team_Content h1 {
  display: flex;
  font-family: "inter";
  width: 30vw;
  color: #181818;
  font-size: 2.8vw;
  font-weight: 600;
  margin-top: 2vw;
}

.Team_Content h4 {
  font-family: "inter";
  width: 27.5vw;
  color: #181818;
  font-weight: 400;
  font-size: 1.2vw;
  letter-spacing: 1px;
}

/* .Team_Image{
    height: 48vw;
    overflow: hidden;
} */
.Team_Image img {
  width: 27vw;
  height: 39.5vw;
}

/* Choose Us  */

.Choose_Main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #272a5c;
  padding: 4vw 3vw 6vw 3vw ;
  gap: 3vw;
}

.Choose_Main h3 {
  color: #ffffff;
  font-size: 2.5vw;
  font-family: "inter";
  font-weight: 500;
}

.Chh_Map {
  display: flex;
  gap: 3vw;
}

.Choose_Us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Choose_Us img {
  width: 10vw;
  margin-left: 2vw;
}

.Choose_Us img:hover {
  transform: scale(1.1);
  transition: 0.2s ease;
  cursor: pointer;
}

.Choose_Us p {
  font-size: 1.2vw;
  font-family: "inter";
  font-weight: 500;
  color: #ffffff;
  margin-top: 2vw;
  margin-left: 2vw;
}

/* Embrace_Container  */

.Embrace_Container_Main {
  display: flex;
  justify-content: center;
  background-color: #e9eaf9;
  padding: 2vw 0 6vw 0;
  margin-bottom: -5.9vw;
}

.Embrace_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Embrace_Container div {
  position: relative;
  width: 22.7vw;
  height: 1.7vw;
  border-radius: 0.7vw;
  background: linear-gradient(to right, #0b1465, #de725b);
  left: 3.5vw;
  top: -3.3vw;
  opacity: 0.5;
}

.Embrace_Container h1 {
  width: 40vw;
  font-size: 2.7vw;
  font-family: "inter";
  text-align: center;
  color: #181818;
  position: relative;
  z-index: 1;
  font-weight: 500;
}

.Embrace_Container p {
  width: 47vw;
  font-size: 1.5vw;
  font-family: "inter";
  text-align: center;
  color: #181818;
  margin-top: -1vw;
  font-weight: 400;
}

.home_arrow_icon {
  color: black;
  font-size: 1.8vw;
  font-weight: 100;
}

@media only screen and (max-width: 780px) {

  /* .Nav_Bar {
        display: none;
      }
      .Image_Content{
        margin-top: 23.7vw;
      } */
  /* .Nav_Bar{
        display: flex;

       } */
  .home_arrow_icon {
    font-size: 5vw;
  }

  .Nav_Bar_hide {

  }

  .MH_Nav_Bar {
    display: flex;
    align-items: center;
    padding: 1rem 6vw;
    gap: 70vw;
  }

  .Nav_Bar img {
    width: 4rem;
    height: 1rem;
  }
  .Nav_Bar_hide  img {
    width: 4rem;
    height: 1rem;
  }

  .Globe_div {
    width: 90vw;
    height: 64vw;
    margin-top: 7vw;
  }

  .Content_Img {
    top: 17vw;
  }

  .Content_Img h1 {
    width: 50vw;
    left: 0vw;
    font-size: 5vw;
  }

  .Content_Img button {
    /* width: 18vw;
    height: 5vw; */
    font-size: 2.8vw;
  }

  .Text_Content h1 {
    width: 80vw;
    font-size: 7vw;
    line-height: 7vw;
  }

  .Text_Content p {
    font-size: 4.3vw;
  }

  .Services-Container h1 {
    font-size: 8vw;
  }

  .Cards_Conatiner {
    grid-template-columns: repeat(1, 1fr);
  }

  .Single_Card {
    width: 70vw;
    height: 50vw;
  }

  .Single_Card img {
    width: 12vw;
    height: 12vw;
  }

  .Single_Card h2 {
    width: 70vw;
    font-size: 5vw;
    /* margin-left: 2vw; */
    text-align: center;
    margin-top: 6vw;
  }

  .Single_Card p {
    font-size: 3vw;
    width: 60vw;
    text-align: center;
    margin-left: 2.5vw;
    margin-top: 9vw;
  }

  .Single_Card div {
    font-size: 5vw;
  }

  .Services-Container h3 {
    font-size: 4vw;
  }

  .Services-Container .Images11 {
    /* width: 45vw; */
    width: 12vw;
    height: 12vw;
  }

  .triangle_canvas {
    border-left: 28vw solid #433277;
    border-top: 28vw solid transparent;
  }

  .explore-more {
    font-size: 4vw;
  }

  .Team_Container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    /* height: 90vw; */
    gap: 10vw;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }

  .Team_Content {
    height: 85vw;
  }

  .Team_Content p {
    font-size: 3vw;
  }
  .who_home_hr{
    width: 60vw;
  }
  .Team_Content h1 {
    width: 60vw;
    font-size: 4vw;
  }

  .Team_Content h4 {
    font-size: 2.7vw;
    width: 60vw;
  }

  .Team_Content hr {
    width: 50vw;
  }

  /* .Team_Image{
    margin-top: 2vw;?
  
} */
  .Team_Image img {
    width: 60vw;
    height: 85vw;
    margin-top: -10vw;
  }

  .Choose_Main h3 {
    font-size: 6vw;
  }

  .Choose_Main {
    margin-top: 9vw;
    padding-bottom: 9vw;
  }

  .Choose_Us img {
    width: 15vw;
  }

  .Embrace_Container {
    width: 80vw;
  }

  .Embrace_Container h1 {
    font-size: 4vw;
    width: 50vw;
  }

  .Embrace_Container div {
    height: 2vw;
    top: -4.4vw;
    width: 35vw;
    left: 5vw;
  }

  .Embrace_Container p {
    font-size: 3.5vw;
    width: 73vw;
  }

  .Image1_Content_Container img {
    height: 64vw;
  }

  .Content_img1 {
    top: 20vw;
  }

  .Content_img1 h1 {
    width: 51vw;
    font-size: 7vw;
    left: 11vw;
  }

  .VerticalLine {
    width: 15vw;
    top: 12vw;
  }

  .Image2_Content_Container img {
    height: 64vw;
  }

  .Content_img2 h1 {
    width: 72vw;
    font-size: 7vw;
    left: 5vw;
  }

  .Content_img2 p {
    width: 60vw;
    font-size: 3.5vw;
    left: 5vw;
  }

  .Content_img2 button {
    font-size: 2.5vw;
    margin-top: 0vw;
    margin-left: 4vw;
  }

  .Image3_Content_Container img {
    height: 64vw;
    margin-top: 7vw;
  }

  .Content_img3 h1 {
    width: 64vw;
    font-size: 7vw;
  }

  .Content_img3 p {
    width: 60vw;
    font-size: 3.5vw;
    left: 1vw;
  }

  .Content_img3 button {
    font-size: 2.5vw;
  }

  .Nav_Links li {
    width: 34vw;
    font-size: 5.3vw;
  }

  .shownavLinks li {
    list-style: none;
  }

  .shownavLinks {
    position: absolute;
    /* top: 2.3rem; */
    margin-left: 2vw;
    margin-top: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    /* gap: 1.5rem; */
    height: 76vw;
    width: 87vw;
    border-radius: 10px;
    background-color: rgba(15, 42, 71, 1);
    box-shadow: 1px 1px 1px 1px;
    text-align: center;
    padding-top: 4vw;
    padding-bottom: 2vw;
    color: black;
  }

  .noNavLink {
    display: none;
  }

  .Nav_Links {
    gap: 1vw;
  }

  .Image_Content {
    /* margin-top: 7vw; */
  }

  .Content_img2 {
    top: 13vw;
  }
  .Choose_Us p{
font-size: 2.5vw;
margin-left: 2vw;
  }
}
