@font-face {
    font-family: 'Helvetica Now Display Regular';
    src: local('Helvetica Now Display Regular'), local('Helvetica-Now-Display-Regular'),
        url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Display Medium';
    src: local('Helvetica Now Display Medium'), local('Helvetica-Now-Display-Medium'),
        url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Display Extra Bold';
    src: local('Helvetica Now Display Extra Bold'), local('Helvetica-Now-Display-Extra-Bold'),
        url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Display Thin';
    src: local('Helvetica Now Display Thin'), local('Helvetica-Now-Display-Thin'),
        url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: -1vw;
    overflow: hidden;
}

.about-container {
    display: flex;
    justify-content: center;
    width: 100%;
    background: linear-gradient(180deg, #0B1465 0%, #8F4F5F 65.22%, #FFFFFF 99.09%);
    position: relative;
}


.about-section-1 {
    width: 88vw;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* z-index: 10; */
    border-radius: 1.5vw;
    padding-bottom: 30vw;
    margin-top: 5vw;
}

.about-section-1-title {
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* margin-top: 3vw; */
    /* row-gap: 1vw; */
}


.about-section-1-title>h1 {
    font-family: "poppins", sans-serif;
    font-weight: 600;
    font-size: 4vw;
    text-align: center;
    background: linear-gradient(90deg, #F7714E 0%, #528EFF 100%);
    -webkit-background-clip: text;
    /* For Webkit-based browsers (e.g., Chrome, Safari) */
    background-clip: text;
    /* Standard property for compatibility */
    color: transparent;
    margin-top: 5vw;
}


.about-section-1-title>p {
    font-family: "inter";
    font-size: 1.5vw;
    font-weight: 500;
    /* line-height: 26.63px; */
    text-align: center;
    color: #524F4F;
    width: 90%;
    margin-top: -1vw;
}

.about-section-1-cards {
    display: flex;
    margin-top: 3vw;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 3.5vw;
    margin-left: 1.3vw;
}

.about-section-1-cards-box {
    width: 28%;
}

.about-section-1-cards-box>h3 {
    font-family: "inter";
    font-weight: 600;
    font-size: 1.8vw;
    color: #000000;
}

.about-section-1-cards-box>img {
    width: 95%;
}

.about-section-1-cards-box>p {
    font-size: 1.3vw;
    font-family: "inter";
    font-weight: 400;
}

/* section-2 */
.about-section-2 {
    position: relative;
    width: 78%;
    margin-top: -25vw;
    border-radius: 12px;
    background-color: #FFFFFF;
    overflow: hidden;
}

.about-section-2::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2vw;
    padding: 4px;
    background: linear-gradient(180deg, #3646DA 0%, #F7714E 100%);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}


.about-section-2-title {
    width: 30%;
    font-family: "inter";
    font-size: 1.8vw;
    font-weight: 600;
    text-align: left;
    padding-left: 5vw;
    margin-top: 4vw;

}

.about-section-2-description {
    display: flex;
    /* justify-content: space-evenly; */
    column-gap: 5vw;
    padding:0vw 5vw;
    margin-top: 2vw;
}

.about-section-2-description-content1 {
    /* width: 30%; */
    margin-left: 5vw;
    /* margin-top: 1vw; */
    text-align: start;
}

.about-section-2-description-content1 p {
    width: 40vw;
    margin-left: 3vw;
}

.about-section-2-description-content2 {
    width: 90%;
}

.about-section-2-description-content1>p {
    font-family: "inter";
    font-size: 1.6vw;
    font-weight: 400;
}

.about-section-2-description-content2>p {
    font-family: "inter";
    font-size: 1.6vw;
    font-weight: 400;
}

.about-section-2-cards {
    display: flex;
    justify-content: space-evenly;
    /* margin-top: 5vw; */
}

.about-section-2-cards-box {
    width: 20%;
    margin-bottom: 6vw;
}

.about-section-2-cards-box>img {
    width: 23vw;
    height: 20vw;
    border-radius: 0.8vw;
    object-fit: cover;

}

.about-section-2-cards-box>h4 {
    font-size: 1.4vw;
    font-family: "inter";
}

.about-section-2-cards-box>p {
    font-size: 0.9vw;
    margin-top: -1vw;
    font-family: "inter";
    /* font-weight: 600; */
}

.about-section3-title {
    /* position: absolute; */
    /* position: relative; */
    /* top: 22vw; */
    margin-top: 4vw;
}

.about-section3-title>div {
    /* border: 40px solid; */
    /* border-image-source: linear-gradient(90deg, #528EFF 0%, #F7714E 100%); */
    background: linear-gradient(90deg, #528EFF 0%, #F7714E 100%);
    border-radius: 30px;
    width: 100%;
    height: 1.5vw;
}

.about-section3-title h1 {
    position: relative;
    top: -2vw;
    font-family: "inter";
    font-size: 2.5vw;
    font-weight: 500;
    text-align: center;
    /* margin-top: -30vw; */
}


/* section - 4 */
/* .about-section4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vw;
}

.about-section4>img {
    width: 10vw;
}

.about-section4>h1 {
    font-family: Inter;
    font-size: 4vw;
    font-weight: 600;
    text-align: center;
    width: 35vw;

}

.about-section4>ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-section4>ul li {
    list-style: none;
    font-family: Inter;
    font-size: 1.5vw;
    font-weight: 500;
}
/* 
.group-containerA {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    column-gap: 2vw;
}

.group-container-boxA {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 18vw;
    height: 5vw;
    border: 1px solid beige;
    border-radius: 5px;
    border: 1px solid #3F5DE6;
    cursor: pointer;
}

.group-container-boxA>img {
    width: 2.5vw;
}

.group-container-boxA>p {
    font-family: Inter;
    font-size: 1.3vw;
    font-weight: 600;

}

.group-boxA {
    background: linear-gradient(90deg, #F23737 -30.18%, #061AD0 94.01%);
}

.group-boxA>p {
    color: #FFFFFF;
} */

/* footer */
/* 
.about-footer {
    background-color: #1A1B38;
    width: 100%;
    margin-top: 4vw;
    padding: 2vw 0;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2vw;
} */

/* .about-footer>p {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 1.2vw;
    font-weight: 400;

}

.about-footer-content {
    border-left: 3px solid white;
    border-right: 3px solid white;
    padding: 0 2vw;
} */





@media only screen and (max-width:780px) {
    .better-service-container {
        top: 15vw;
    }

    .about-container {
        margin-top: 6vw;
    }

    .about-page {
        margin-top: 5.7vw;
    }

    .about-section-1-title>h1 {
        font-size: 7vw;
    }

    .about-section-1-title>p {
        font-size: 3vw;
    }

    .about-section-1-cards {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .about-section-1-cards-box {
        width: 70vw;
    }

    .about-section-1-cards-box>h3 {
        font-size: 3.5vw;
        margin-left: 25vw;
    }

    .about-section-1-cards-box>p {
        font-size: 3vw;
        width: 66vw;
        text-align: center;
    }

    .about-section-2-title {
        font-size: 3vw;
        width: 72%;
        margin-left: 8vw;
    }

    .about-section-2-description {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .about-section-2-description-content1 {
        width: 70%;
    }

    .about-section-2-description-content1>p {
        font-size: 3vw;
    }

    .about-section-2-description-content2>p {
        font-size: 3vw;
    }

    .about-section-2-description-content2 {
        width: 70%;
    }

    .about-section-2-cards {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .about-section-2-cards-box {
        width: 70vw;
    }

    .about-section-2-cards-box>img {
        width: 60vw;
        height: 50vw;
        margin-left: 5vw;
    }

    .about-section-2-cards-box>h4 {
        font-size: 4vw;
        margin-left: 6vw;
    }

    .about-section-2-cards-box>p {
        font-size: 3vw;
        margin-left: 6vw;
    }

    .about-section3-title h1 {
        font-size: 3vw;
    }
    .about-section-2-description-content1 p {
        width: 60vw;
        margin-left: -9vw;
    }
    .about-section-2::after{
        border-radius: 5vw;
    }
}