@font-face {
  font-family: "Helvetica Now Display Regular";
  src: local("Helvetica Now Display Regular"),
    local("Helvetica-Now-Display-Regular"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Display Medium";
  src: local("Helvetica Now Display Medium"),
    local("Helvetica-Now-Display-Medium"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Display Extra Bold";
  src: local("Helvetica Now Display Extra Bold"),
    local("Helvetica-Now-Display-Extra-Bold"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.ttf")
      format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Display Thin";
  src: local("Helvetica Now Display Thin"), local("Helvetica-Now-Display-Thin"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
}
.who-we-are {
  display: flex;
  gap: 3px;
}
.nav_who-we-are {
  display: flex;
  width: 8vw;
}
.nav_what_we_do {
  display: flex;
  width: 8vw;
}
.navbar {
  position: fixed;
  top: 0;
  display: flex;
  gap: 31.5vw;
  z-index: 20000;
  background-color: #010724;
  padding: 1.5vw 10vw 1vw 10vw;
  box-shadow: 1px 1px 2px;
}

.navbar_hide {
  position: fixed;
  top: 0vw;
  display: flex;
  gap: 31.5vw;
  z-index: 20000;
  /* background-color: #010724; */
  padding: 1.5vw 10vw 1vw 10vw;
  /* box-shadow: 1px 1px 2px; */
}

.NavImage {
  position: relative;
  width: 100%;
  height: 31.063vw;
  /* margin-top: 5vw; */
}

.NavImage img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.NavImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0d1565 0%, rgba(152, 32, 108, 0.3) 100%);
  pointer-events: none;
}

.navItems {
  display: flex;
  color: aliceblue;
  gap: 4vw;
}

.navItems li {
  list-style: none;
  color: #dfe3fb;
  font-family: "Helvetica Now Display Regular";
  font-size: 1.3vw;
  font-weight: 600;
}
.Nav_links {
  color: #dfe3fb;
  text-decoration: none;
}
/* .Nav_links:focus {
  color:rgb(131, 130, 125);
  transform: scale(1.5);
} */

.vsdl {
  color: aliceblue;
  position: relative;
  left: 8vw;
}

.create-navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.4vw;
  position: relative;
  bottom: 20vw;
  z-index: 1;
  gap: 20vw;
}

.navbar img {
  width: 10vw;
  margin-top: 0.4vw;
}
.navbar_hide img{
   width: 10vw;
  margin-top: 0.4vw;
}
.CuroselIcon,
.CrossMark {
  display: none;
}

.better-service-container {
  position: absolute;
  top: 6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.better-service {
  color: aliceblue;
  font-size: 3vw;
  margin-top: 8vw;
  font-family: inter;
  line-height: 6.313vw;
  /* font-family: "Helvetica Now Display Medium"; */
}
.CuroselIcon {
  color: #ffffff;
  position: relative;
  z-index: 20;
}

.better-service-container > button {
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  width: 10vw;
  height: 3vw;
  font-size: 1.3vw;
  font-family: Inter;
  font-weight: 600;
  margin-top: -1.5vw;
  cursor: pointer;
}

/* Mobile View  */

@media only screen and (max-width: 780px) {
  /* .navbar{
    /* height: 1vw; */
  /* }  */
  .navbar {
    width: 100vw;
  }
  .CuroselAndImg {
    display: flex;
    align-items: center;
    padding: 5vw 6vw;
    gap: 65vw;
  }
  .CuroselAndImg img {
    width: 18vw;
    height: 5vw;
  }
  .NavImage {

    /* margin-top: 5.9rem; */
    /* margin-bottom: 0rem; */
  }
  .NavImage img {
    height: 44vw;
    margin-top: -6vw;
  }
  .NavImage::before {
    height: 44vw;
  }
  .better-service {
    color: aliceblue;
    font-size: 1.3rem;
    margin-top: 1.6rem;
  }
  .CuroselIcon,
  .CuroselIcon {
    color: #dfe3fb;
    display: block;
    font-size: 5vw;
    cursor: pointer;
  }
  .noNav {
    display: none;
  }
  .shownavItems {
    position: absolute;
    margin-left: 2vw;
    margin-top: 16vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: 7vw;
    height: 80vw;
    width: 87vw;
    border-radius: 10px;
    background-color: rgba(15, 42, 71, 1);
    box-shadow: 1px 1px 1px 1px;
    text-align: center;
    padding-top: 2rem;
    color: black;
  }
  .ShownavItems li {
    list-style: none;
  }
  .Nav_links {
    font-size: 5vw;
    margin-right: 30px;

    /* width: 17vw; */
  }
  .CrossMark {
    display: block;
    font-size: 1.5rem;
    color: #dfe3fb;
  }
  .navItems li {
    width: 46vw;
  }
  .nav_who-we-are {
    display: flex;
    width: 35vw;
    padding-left: 6vw;
  }
  .nav_what_we_do {
    display: flex;
    width: 35vw;
    padding-left: 6vw;
  }
  /* .who-we-are {
    display: flex;
    gap: -3vw;
    text-align: left;
   
  }
  .who-we-are li:nth-child(3) {
    text-align: left;
    position: relative;
    right: 3vw;
  }
  .who-we-are li {
    font-size: 5vw;
  } */
}
