@font-face {
  font-family: 'Helvetica Now Display Regular';
  src: local('Helvetica Now Display Regular'), local('Helvetica-Now-Display-Regular'),
      url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff2') format('woff2'),
      url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff') format('woff'),
      url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Display Medium';
  src: local('Helvetica Now Display Medium'), local('Helvetica-Now-Display-Medium'),
      url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff2') format('woff2'),
      url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff') format('woff'),
      url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Display Extra Bold';
  src: local('Helvetica Now Display Extra Bold'), local('Helvetica-Now-Display-Extra-Bold'),
      url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff2') format('woff2'),
      url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff') format('woff'),
      url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Display Thin';
  src: local('Helvetica Now Display Thin'), local('Helvetica-Now-Display-Thin'),
      url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff2') format('woff2'),
      url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff') format('woff'),
      url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

.over-all-footer {
    background-color: #1A1B38;
    width: 100%;
    padding: 2vw 0;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2vw;
    margin-top: 4vw;
  }
  
  .over-all-footer > p {
    color: #FFFFFF;
    font-family: 'Helvetica Now Display Regular';
    font-size: 1.2vw;
    /* font-weight: 400; */
  }

  .over-all-footer .coloring{
    color: #FFFFFF;
    font-size: 1.3vw;
    cursor: pointer;
  }
  
  .over-all-footer > p:hover {
    cursor: pointer;
  }
  
  .footer-content {
    border-left: 3px solid white;
    border-right: 3px solid white;
    padding: 0 2vw;
  }




  @media only screen and  (max-width:780px){
    .over-all-footer > p{
      font-size: 3.5vw;
    }
    .over-all-footer .coloring{
      font-size: 3.5vw;
    }
    .coloring{
        margin-top: 1vw;
    }
  }
  