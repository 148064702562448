.backTransition {
  background: linear-gradient(180deg, #0b1465 0%, #8f4f5f 65.22%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-family: "poppins";
  height: 100vh;
  overflow: hidden;
}
.outer_top_circle {
  position: relative;
  /* top: 0vw; */
  top: 5vw;
  left: -50vw;
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  background: linear-gradient(180deg, #f7714e 0%, #3343d6 100%);
}
.bottom_down_circle {
  position: relative;
  /* top: 0vw; */
  bottom: 17vw;
  right: -50vw;
  width: 15vw;
  height: 15vw;
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  background: linear-gradient(180deg, #f7714e 0%, #3343d6 100%);
}
.card {
  background-color: #fff;
  padding: 2vw;
  border-radius: 0.5vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin-top: 4vw;
  font-family: "poppins";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64vw;
}
.card1 {
  background-color: #fff;
  padding: 2vw;
  border-radius: 0.5vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin-top: 2vw;
  font-family: "poppins";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card h1 {
  background: linear-gradient(90deg, #081acf 0%, #f7714e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "poppins";
  margin-top: 0vw;
  font-size: 2vw;
}
.heading_cp{
  font-size: 2vw;
}
.card form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CP_inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.input_fields {
  display: flex;
  gap: 3vw;
}
.card form input:focus {
  border: 1px solid #ccc;
  outline: none;
}
.card form textarea:focus {
  border: 1px solid #ccc;
  outline: none;
}

.input_fields input {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "inter";
  margin: 0.5vw 0vw;
  border-radius: 0.5vw;
  width: 31vw;
  font-size: 1.3vw;
  padding: 0.8vw;
}
.input_fields1 {
  display: flex;
  flex-direction: column;
}
.input_fields1 textarea {
  width: 65vw;
  font-size: 1.3vw;
  padding: 0.8vw;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "inter";
  margin: 0.5vw 0vw;
  border-radius: 0.5vw;
}
.position-save {
  border: none;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw;
  background: linear-gradient(90deg, #f23737 -30.18%, #061ad0 94.01%);
  color: #fff;
  font-family: "inter";
  margin-left: 5vw;
  font-size: 1.2vw;
  cursor: pointer;
}
.two-btn {
  display: flex;
  justify-content: center;
  font-family: "inter";
  align-items: center;
  /* gap: 5vw; */
  margin-top: 2vw;
  cursor: pointer;
}
.position_status {
  margin-left: -12vw;
}
.position_status label{
  font-size: 1.1vw;

}
.position_status select {
  width: 10vw;
  padding: 0.5vw;
  cursor: pointer;
  border-radius: 0.5vw;
  margin-left: 1vw;
  font-size: 1.1vw;
}
.dleteFunctionality {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  /* border-radius: 10px; */
  font-family: "poppins";
  width: 30vw;
  justify-content: center;
  align-items: center;
  gap: 8vw;
}
.dleteFunctionality ul{
  margin-left: -4vw;
}
.dleteFunctionality ul li{
  list-style: none;
}
.closeee {
  margin-top: 1vw;
  font-family: "inter";
  /* text-align: center; */
  margin-left: 0vw;
}
.capitalize {
  text-transform: capitalize;
}
.function_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vw;
}
.delete-btn {
  /* padding: 5px 5px 5px 5px; */
  /* height: 20px;
  margin-top: 1.5vw;
  border-radius: 5px;
  background: linear-gradient(180deg, #f7714e 0%, #3343d6 100%); */
  font-size: 2vw;
  cursor: pointer;
}
.cp_edit_btn {
  font-size: 1.8vw;
  cursor: pointer;
}
.description-field {
  width: 92%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "inter";
  height: 10vw;
  resize: none;
  margin: 10px 0;
}
/* 
@media (max-width: 1200px) {
  .round-curcle {
    width: 80vw;
    height: 80vw;
  }
  .card {
    width: 35vw;
    height: auto;
  }
  .position-save {
    left: 15vw;
    top: 15vw;
  }
}

@media (max-width: 992px) {
  .round-curcle {
    width: 80vw;
    height: 80vw;
  }
  .card {
    width: 35vw;
    height: auto;
  }
  .position-save {
    left: 12vw;
    top: 10vw;
  }
}

@media (max-width: 768px) {
  .round-curcle {
    width: 80vw;
    height: 80vw;
  }
  .card {
    width: 40%;
    height: auto;
  }
  .vsdl {
    left: 43vw;
    top: 10vw;
  }
  .position-save {
    left: 0.5vw;
    top: -0.1vw;
  }
}
.dleteFunctionality ul {
  list-style-type: none;
}

@media (max-width: 576px) {
  .round-curcle {
    width: 120vw;
    height: 120vw;
  }
  .card {
    width: 60%;
    height: auto;
  }
  .card h1 {
    font-size: 4vw;
  }
  .position-save {
    left: 1vw;
    top: -0.1vw;
  }
  .vsdl {
    left: 38vw;
    top: -4vw;
  }
  .backTransition {
    height: 121vw;
  }
}

@media (max-width: 576px) {
  .backTransition {
    min-height: 100vh; 
  }
  .round-curcle {
    width: 120vw;
    height: 120vw;
  }
  .card {
    width: 60%;
    height: auto;
  }
  .card h1 {
    font-size: 4vw;
  }
  .position-save {
    left: 1vw;
    top: -0.1vw;
  }
  .vsdl {
    left: 38vw;
    top: 18vw;
  }
  .backTransition {
    height: 100vh; 
}
} */
@media screen and (min-width:320px) and (max-width:720px) {
  .card{
    width: 70vw;
    height: auto;
    padding: 2vw 2vw;
  }
  .input_fields{
  display: flex;
  flex-direction: column;
  gap: 0vw;
  }
  .card h1{
font-size: 4vw;
  }
  .input_fields input{
    width: 65vw;
    height: 6vw;
    font-size: 2.5vw;
    padding: 0.5vw;
  }
  .input_fields1 textarea{
    width: 65vw;
    height: 15vw;
    font-size: 2.5vw;
    padding: 0.5vw;
  }
  .two-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3vw;
  }
  .position-save{
    font-size: 3vw;
    padding: 2vw 3vw;
  }
  .position_status{
    margin-left: 2vw;
  }
  .position_status label{
    font-size: 3vw;
  }
  .position_status select{
    font-size: 3vw;
    width: 25vw;
  }
}
