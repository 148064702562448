/* @font-face {
    font-family: 'Helvetica Now Display Regular';
    src: local('Helvetica Now Display Regular'), local('Helvetica-Now-Display-Regular'),
        url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica Now Display Medium';
    src: local('Helvetica Now Display Medium'), local('Helvetica-Now-Display-Medium'),
        url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica Now Display Extra Bold';
    src: local('Helvetica Now Display Extra Bold'), local('Helvetica-Now-Display-Extra-Bold'),
        url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica Now Display Thin';
    src: local('Helvetica Now Display Thin'), local('Helvetica-Now-Display-Thin'),
        url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  } */

.termsandcondition-container{
    padding: 6vw 10vw 0vw 10vw;
}
.termsandcondition-container h1{

    font-family: poppins;
    font-size: 3vw;
    text-align: center;
    color: transparent;
    background: linear-gradient(90deg, #F7714E 0%, #528EFF 100%);
    background-clip: text;
}
.termsandcondition-container p{
    font-family: inter;
    font-size: 1.2vw;
    text-align: justify;
}
.termsandcondition-container h2{
    font-family: poppins;
    font-size: 2vw;
}
@media screen and (max-width:720px){
    .termsandcondition-container{
        padding: 15vw 10vw 0vw 10vw;
        margin-top: 2vw;
    }
    .termsandcondition-container h1{
        font-size: 5.5vw;
    }.termsandcondition-container p{
        font-size: 2.6vw;
    }.termsandcondition-container h2{
        font-size: 3.2vw;
    }
}