@font-face {
  font-family: "Helvetica Now Display Regular";
  src: local("Helvetica Now Display Regular"),
    local("Helvetica-Now-Display-Regular"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Display Medium";
  src: local("Helvetica Now Display Medium"),
    local("Helvetica-Now-Display-Medium"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Display Extra Bold";
  src: local("Helvetica Now Display Extra Bold"),
    local("Helvetica-Now-Display-Extra-Bold"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.ttf")
      format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Display Thin";
  src: local("Helvetica Now Display Thin"), local("Helvetica-Now-Display-Thin"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff2")
      format("woff2"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff")
      format("woff"),
    url("../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
}

.career-container {
  background: linear-gradient(
    180deg,
    #0b1465 0%,
    #8f4f5f 65.22%,
    #ffffff 99.09%
  );
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1vw;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  /* z-index: -1; */
}
.vsdl-heading-cancel {
  position: sticky;
  top: 0; /* Sticks to the top */
  z-index: 100; /* Ensures it's on top of other content */
  background-color: white;
  padding: 0.5vw 9vw 0.5vw 9vw;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}
.circle-box1 {
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  background: linear-gradient(180deg, #f7714e 0%, #3343d6 100%);
}

.carrer-circle1 {
  position: absolute;
  top: 15vw;
  left: -7vw;
  /* z-index: -1; */
}

.carrer-circle2 {
  position: absolute;
  left: 86vw;
  top: 60vw;
  /* z-index: 12;  */
}

.career-main {
  border-radius: 20px;
  width: 85vw;
  padding: 1vw 0vw 18vw 0vw;
  background: rgba(255, 255, 255, 1);
  margin-top: 5vw;
  position: relative;
  /* z-index: 10; */
}

.career-main > h1 {
  margin-left: 6vw;
  margin-top: 4vw;
  font-size: 3vw;
  font-family: "inter";
  font-weight: 600;
}

.career-section1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vw;
gap: 3vw;
}

.career-section1-content1 {
  width: 22vw;
  /* height: 30vw; */
}
.career-section1-content2 {
  width: 22vw;
  /* height: 30vw; */
}

.career-contents > h3 {
  font-family: "inter";
  font-size: 1.6vw;
  font-weight: 600;
}

.career-contents > p {
  font-family: "inter";
  font-size: 1.1vw;
  font-weight: 400;
  margin-top: -1vw;
}
.career-contents1 > h3 {
  font-family: "inter";
  font-size: 1.6vw;
  font-weight: 600;
}

.career-contents1 > p {
  font-family: "inter";
  font-size: 1.1vw;
  font-weight: 400;
  margin-top: -1vw;
}

.career-section1 > img {
  width: 23vw;
  height: 30vw;
}

/* section 2 */
.career-section2 {
  display: flex;
  flex-direction: column;
  margin-top: 5vw;
}

.career-section2 > h1 {
  font-family: "inter";
  font-size: 3vw;
  font-weight: 600;
  margin-left: 5vw;
}

.career-section2-main {
  display: flex;
  /* justify-content: space-around; */
  /* align-items: center; */
}

.career-section2-main-container {
  display: flex;
  flex-direction: column;
  /* width */
  width: 45vw;
  margin-left: 1.8vw;
}

.career-section2-content-box > h4 {
  font-size: 1.5vw;
  font-family: "inter";
  margin-bottom: -0.5vw;
  font-weight: 600;
  text-transform: capitalize;
}
.career-section2-content-box {
  width: 18vw;
}
.career-section2-content {
  margin-left: 2.5vw;
}
.career-section2-content-box > p {
  font-size: 1.1vw;
  font-family: "inter";
  font-family: 500;
}

.career-section2-content {
  width: 30vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.career-section2-content > button {
  position: relative;
  width: 8vw;
  height: 3vw;
  border-radius: 1vw;
  border: none;
  background: white;
  font-family: "inter";
  /* z-index: 1; */
  font-weight: 500;
  overflow: hidden;
  font-size: 1.2vw;
  cursor: pointer;
}

.career-section2-content > button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  padding: 2px;

  background: linear-gradient(180deg, #010724 0%, #f7714e 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.career-section2-side {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 45vw;
}

.career-section2-side > div {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* align-items: center; */
  width: 25vw;
}

.career-section2-side h3 {
  width: 18vw;
  font-size: 1.5vw;
  font-family: "inter";
  font-weight: 600;
}

.career-section2-side .arrow_career {
  cursor: pointer;
  width: 1.5vw;
  height: 1.5vw;
  padding: 1vw;
  border-radius: 50%;
  margin-top: 2vw;
  background: linear-gradient(90deg, #081acf 0%, #f7714e 100%);
  color: white;
  /* font-size: 3vw; */
}

.career-section3-title {
  margin-top: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.career-section3-title > div {
  background: linear-gradient(90deg, #528eff 0%, #f7714e 100%);
  border-radius: 30px;
  width: 68vw;
  height: 1.8vw;
}

.career-section3-title h1 {
  position: relative;
  top: -3.5vw;
  left: 0.2vw;
  font-size: 2.5vw;
  font-weight: 500;
  text-align: center;
  font-family: "inter";
}

.career-footer {
  margin-top: 8vw;
}

.better-service-container > button {
  font-family: "inter";
}

/* .application-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .application-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  } */

/* .application-modal {
  position: fixed;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.application-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
} */

/* .application-modal{
    position: relative;
    background-color: white;
    width: 70vw;
    height: 50vh;
} */

/* kdiqs */
/* Disable background scroll when modal is open */
body.modal-open {
  overflow: hidden;
}
body.modal-open1 {
  overflow: hidden;
}
/* Modal background overlay */
.application-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-family: "inter";
}
.application-modal1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-family: "inter";
}
.application-modal1-main {
  display: flex;
  justify-content: space-between;
  margin-top: 2vw;
}
.application-modal1-date {
  margin-top: 1vw;
}
/* Modal content box */
.application-modal-content {
  background-color: #fff;
  padding: 18px;
  border-radius: 8px;
  width: 90vw;
  max-width: 70%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  font-family: "inter";
}
.application-modal-content1 {
  background-color: #fff;
  padding: 0vw 5vw 2vw 5vw;
  width: 90vw;
  max-width: 50%;
  max-height: 90%;
  overflow-y: auto;
  scrollbar-width: none;
  overflow-x: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  font-family: "inter";
}

.application-modal-content1 button {
  /* margin-left: 5vw;
  width: 40vw;
  height: 4vw;
  background-color: #5622bd;
  color: white;
  font-size: 1.5vw;
  font-family: "inter";
  outline: none;
  border: none;
  cursor: pointer; */
}
.application-modal-content1 h1 {
  font-size: 1.5vw;
  font-family: "inter";
}
.application-modal-content1 h2 {
  color: #5622bd;
}
.application-modal-content1 hr {
  color: #5622bd;
  border: 1px solid #5622bd;
}
/* Close button at the top right of the modal */
.application-modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: black;
  font-family: "inter";
}
.application-modal-content1 .close-button1 {
  position: absolute;
  top: 0vw;
  right: 0vw;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: black;
  font-family: "inter";
}
.application-modal1-heading h1 {
  font-size: 2vw;
  font-family: "poppins", sans-serif;
}
.application-modal1-date{
  display: flex;
}
.application-modal1-date h1{
font-size: 1.3vw;
}
.application-modal1-date p {
  color: #5622bd;
  font-size: 1.3vw;
  margin-top: 0.9vw;
  margin-left: 0.5vw;
}
.application-modal-content1 h1 {
  text-align: center;
}
.application-modal-content1 ul li{
  text-transform: capitalize;
}
.job_description{
  text-align: justify;
}
/* Form fields */
.application-modal-content form {
  display: flex;
  flex-direction: column;
}

.application-modal-content form label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.application-modal-content form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}
.application-modal-content form input:focus {
  border: 1px solid #ccc; 
  outline: none;
}
.application-modal-content form textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.application-modal-content form textarea:focus {
  border: 1px solid #ccc; 
  outline: none;
}
.resmue_sending_mail{
  width: 50vw;
  /* margin-left: 5vw; */
  height: 4vw;
  background-color: #5622bd;
  color: white;
  font-size: 1.5vw;
  font-family: "inter";
  outline: none;
  border: none;
  cursor: pointer;
}
.application-modal-content form button {
  padding: 10px;
  background: linear-gradient(90deg, #f23737 -30.18%, #061ad0 94.01%);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-family: "inter";
}
.career-section2-content-box > p {
  font-family: "inter";
}
.application-modal {
  font-family: "inter";
}
/* endfdakj  */

@media only screen and (max-width: 780px) {
  .career-container {
    margin-top: 12vw;
  }

  .career-main > h1 {
    font-size: 5vw;
  }
  .career-section1 {
    display: flex;
    justify-content: center;
    /* margin-top: 2vw; */
    gap: 8vh;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }
  .career-section1-content1 {
    width: 60vw;
    height: 65vw;
    /* margin-top: 9vw; */
  }
  .career-section1-content2 {
    width: 60vw;
    height: 65vw;
    margin-top: 9vw;
  }
  .career-contents > h3 {
    font-size: 4vw;
  }
  .career-contents > p {
    font-size: 3vw;
  }
  .career-contents1 > h3 {
    font-size: 4vw;
  }
  .career-contents1 > p {
    font-size: 3vw;
  }
  .career-section1 > img {
    width: 60vw;
    height: 70vw;
  }
  .career-section2 > h1 {
    font-size: 7vw;
    margin-left: 22vw;
  }
  .career-section2-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .career-section2-main-container {
    width: 72vw;
  }
  .career-section2-content {
    width: 50vw;
    margin-left: 12vw;
  }
  .career-section2-content-box {
    width: 39vw;
  }
  .career-section2-content-box > h4 {
    font-size: 4vw;
  }
  .career-section2-content-box > p {
    font-size: 2.4vw;
  }
  .career-section2-content > button {
    width: 36vw;
    height: 7vw;
  }
  .career-section2-side {
    width: 55vw;
  }
  .career-section2-side > div {
    width: 49vw;
  }
  .career-section2-side h3 {
    width: 35vw;
    height: 5vw;
    font-size: 3vw;
  }
  .career-section2-side .arrow_career {
    width: 4vw;
    height: 4vw;
    margin-top: 3.2vw;
  }
  .better-service-container > button {
    width: 19vw;
    height: 6vw;
    font-size: 3vw;
  }
  .career-section3-title h1 {
    font-size: 3vw;
    width: 90vw;
    top: -4vw;
    left: -1vw;
  }
  .career-section3-title > div {
    width: 88vw;
  }
  .application-modal1 {
    width: 124%;
    height: 100%;
    margin-left: -10vw;
  }
  .application-modal1-heading h1 {
    font-size: 4vw;
  }
  .application-modal1-date p {
    font-size: 2vw;
    margin-top: 3vw;
  }
  .application-modal-content1 h1 {
    font-size: 4vw;
  }
  .application-modal-content1 h2 {
    font-size: 4vw;
  }
  .application-modal-content1 li {
    font-size: 3vw;
  }
  .application-modal-content1 p {
    font-size: 3vw;
  }
  .application-modal-content1 button {
    width: 51vw;
    height: 7vw;
    font-size: 2vw;
  }
  .application-modal-content1 {
    max-height: 71%;
  }
}
