@font-face {
  font-family: 'Helvetica Now Display Regular';
  src: local('Helvetica Now Display Regular'), local('Helvetica-Now-Display-Regular'),
      url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff2') format('woff2'),
      url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff') format('woff'),
      url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Display Medium';
  src: local('Helvetica Now Display Medium'), local('Helvetica-Now-Display-Medium'),
      url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff2') format('woff2'),
      url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff') format('woff'),
      url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Display Extra Bold';
  src: local('Helvetica Now Display Extra Bold'), local('Helvetica-Now-Display-Extra-Bold'),
      url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff2') format('woff2'),
      url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff') format('woff'),
      url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Display Thin';
  src: local('Helvetica Now Display Thin'), local('Helvetica-Now-Display-Thin'),
      url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff2') format('woff2'),
      url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff') format('woff'),
      url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}


.PreFoter-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PreFoter-section>img {
  width: 10vw;
}

.PreFoter-section>h1 {
font-family: inter; 
 font-size: 4vw;
  font-weight: 600;
  /* line-height: 77.45px; */
  text-align: center;
  width: 35vw;

}

.Addres {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* color: rgba(0, 0, 0, 0); */
}

.Addres p{
  list-style: none;
  font-family: inter; 
  font-size: 1.5vw;
  font-weight: 500;
  line-height: 0vw;
}

.group-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 2vw;
}

.group-container-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 18vw;
  height: 5vw;
  border: 1px solid beige;
  border-radius: 5px;
  border: 1px solid #3f5ee61f;
  margin-top: 2vw;
  &:hover{
      cursor: pointer;
  }
}


.group-container-box>img {
  width: 2.5vw;
}

.group-container-box>p {
  font-family: "Helvetica Now Display Regular"; 
  font-family: Inter;
  font-size: 1.3vw;
  /* font-weight: 600; */

}

.group-box {
  background: linear-gradient(90deg, #F23737 -30.18%, #061AD0 94.01%);
}

.group-box>p {
  color: #FFFFFF;
  font-family: "Helvetica Now Display Regular"; 

}


@media only screen and  (max-width:780px){
  .PreFoter-section>img{
    width: 20vw;
  }.PreFoter-section>h1{
    font-size: 5vw;
    width: 40vw;
  }.Addres p{
    font-size: 3vw;
  }
.group-container-box{

 width: 30vw;
 height: 8vw; 
}
.group-container{
  display: flex;
  flex-direction: row;
  column-gap: 2vw;
}.group-container-box>img{
  width: 5vw;
}
.group-container-box>p{
  font-size: 3vw;
}

/* .Addres{
  margin-bottom: 15vw;
} */
/* .group-box{
  margin-top: -20vw;
} */
/* #group-container-box2{
 margin-left: 10vw; 
}
#group-container-box1{
  margin-right: 10vw;
} */
}

@media screen and (max-width:780px) {
  .group-container-box{
    width: 35vw;
  }
  
}