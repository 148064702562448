@font-face {
    font-family: 'Helvetica Now Display Regular';
    src: local('Helvetica Now Display Regular'), local('Helvetica-Now-Display-Regular'),
        url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Display Medium';
    src: local('Helvetica Now Display Medium'), local('Helvetica-Now-Display-Medium'),
        url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Display Extra Bold';
    src: local('Helvetica Now Display Extra Bold'), local('Helvetica-Now-Display-Extra-Bold'),
        url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Display Thin';
    src: local('Helvetica Now Display Thin'), local('Helvetica-Now-Display-Thin'),
        url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff2') format('woff2'),
        url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff') format('woff'),
        url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

.Main_Contact_Bg {
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #0B1465 0%, #8F4F5F 65.22%, #FFFFFF 85.09%);
    padding: 5vw 0vw 10vw 0vw;
    margin-top: -36vw;
}

/* Circles   */
.Circle1 {
    position: relative;
    width: 15vw;
    height: 15vw;
    border-radius: 100%;
    background: linear-gradient(180deg, #F7714E 0%, #3343D6 100%);
    top: 50vw;
    left: 83.5vw;
    overflow: hidden;
}

.Circle2 {
    position: relative;
    width: 15vw;
    height: 15vw;
    border-radius: 100%;
    background: linear-gradient(180deg, #F7714E 0%, #3343D6 100%);
    top: 0vw;
    right: 7vw;
}

/* Forms  */

.Form_Main {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 6vw;
    border-radius: 2vw;
    width: 85vw;
    padding: 2vw 0vw 20vw 0vw;
    background: rgba(255, 255, 255, 1);
    margin-top: 5vw;
}


.Form_Details {
    width: 48.563vw;
}

.Form_Details h1 {
    width: 28vw;
    font-size: 4.2vw;
    font-weight: 600;
    font-family: inter;
    background: linear-gradient(90.87deg, #3646DA 0.75%, #F7714E 50.12%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom:5vw;
}

.Form_Details p {
    font-size: 2vw;
    font-family: inter;
    font-weight: 600;
    margin-bottom: 3.5vw;
}

.Forms {
    display: flex;
    flex-direction: column;
    gap: 3vw;
}

.Form_feild {
    display: flex;
    flex-direction: column;
    /* row-gap: 1vw; */
}

.TwoFeilds_Form {
    display: flex;
    gap: 4vw;
}

.Form_feild input {
    outline: none;
    border: none;
    border-bottom: .15vw solid rgba(0, 0, 0, .9) !important;
    /* width: 15vw; */
    padding: 1vw  0vw;
    font-family: inter;
    width: 20.662vw;
    font-size: 1.1vw;
     margin-top: 0.4vw;
}
.Form_feild input[type="text"] {
    outline: none;
    border: none;
    margin-top: 0.4vw;
    font-family: inter;
    border-bottom: .15vw solid rgba(0, 0, 0, .9) !important;
    /* width: 15vw; */
    padding: 1vw 0vw;
    width: 20.662vw;
    font-size: 1.1vw;
    border-radius: 0px;
}
.textarea_input{
    width: 45.499vw !important;
    padding: 1vw 0vw;
}

.Form_feild label {
    font-size: 1.3vw;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    font-family: inter;
}

.Form_feild input::placeholder {
    font-size: 1.1vw;
    background: linear-gradient(180deg, #515050 0%, #B7B3B3 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: inter;

}

.Form_feild input:focus,
.Form_feild input:active {
    outline: none;
}

.Form_feild #Message {
    width: 35vw;
}

.Form_feild button {
    width: 6.875vw;
    padding: 0.5vw;
    border: none;
    border: .15vw solid black;
    border-radius: 0.999vw;
    font-size: 1.1vw;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
cursor: pointer;
background-color: transparent;


}

/* Chat US  */

.Chat_With_Us {
    width: 19.027vw;
    display: flex;
    flex-direction: column;

}

.chat_svg_images{
    width: 2.217vw;
}

.Chat_Us {
    display: flex;
    gap: 1vw;
    margin-top: 2.5vw;
}

.Chat_Us .Icon {
    font-size: 3vw;
    margin-top: 1.2vw;
}

.Chat_Us h1 {
    color: rgba(0, 0, 0, 1);
    font-size: 2vw;
    font-family: inter;
    font-weight: 600;

}

.Chat_Us p {
    font-size: 1vw;
    width: 20vw;
    font-family: 'Helvetica Now Display Regular';
    /* font-weight: 400; */
    background: linear-gradient(180deg, #515050 0%, #B7B3B3 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Chat_Us span {
    display: block;
    font-size: 1vw;
    font-weight: 600;
    font-family: inter;
    padding: 0.3vw;
cursor: pointer;

}

#PhNumber::-webkit-outer-spin-button,
#PhNumber::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#PhNumber {
    -moz-appearance: textfield;
}

@media only screen and (max-width:780px) {
    .Main_Contact_Bg {
        margin-top: -18vw;
    }

    .Form_Main {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: space-around;
        align-items: flex-start;
        justify-content: center;
    }

    .Form_Details h1 {
        width: 70vw;
        font-size: 5vw;
        margin-left: -10vw;
    }

    .Form_Details p {
        font-size: 4vw;
       text-align: center;
       margin: 6vw 0vw;
       
    }

    .TwoFeilds_Form {
        display: flex;
        gap: 4vw;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
        width: 70vw;
    }

    .Form_feild {
        display: flex;
        flex-direction: column;
        gap: 1vw;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        width: 70vw;
        margin: 1vw 0vw;

    }

    .Form_feild label {
        font-size: 3.5vw;
    }

    .Form_feild input {
        font-size: 4vw;
        width: 68vw;
    }
    .Form_feild input[type="text"] {
        font-size: 4vw !important;
        width: 68vw;
    /* width: 15vw; */
    padding: 1vw 0vw;
    font-size: 1.1vw;
    border-radius: 0px;
    }
    

    .textarea_input{
    width: 68vw !important;
    padding: 1vw 0vw;
}

.phone_number{
    margin: 5vw 0vw;
}
    .Form_feild input::placeholder {
        font-size: 3vw;
    }.Form_feild button{
        width: 20vw;
        height: 7vw;
        font-size: 3vw;
        margin-left: 18vw;
    }

    .Forms {
        display: flex;
        flex-direction: column;
        width: 60vw;
        margin-left: -10vw;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        align-items: center;
    }

    .Form_feild #Message {
        width: 55vw;
    }

    .Chat_With_Us {
        width: 40vw;
        display: flex;
        flex-direction: column;
    }

    .Chat_Us .Icon {
        font-size: 9vw
    }

    .Chat_Us h1 {
        font-size: 4.5vw;
        width: 30vw;
    }

    .Chat_Us span {
        font-size: 3.5vw;
        width: 40vw;
    }

    .Circle2 {
        top: 20vw
    }

    .Circle1 {
        top: 150vw;
    }

    .Chat_Us {
        display: flex;
        gap: 2vw;
        margin-top: 2.5vw;
        align-items: center;
        justify-content: center;
        align-items: center;
    }

    .chat_svg_images{
        width: 8vw;
        margin-top: 0vw;
    }
}