@font-face {
  /* font-family: 'Helvetica Now Display Regular'; */
  font-family: "Helvetica Now Display Medium";
  src: local('Helvetica Now Display Regular'), local('Helvetica-Now-Display-Regular'),
    url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff2') format('woff2'),
    url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.woff') format('woff'),
    url('../../../public/HelveticaNowDisplay-Regular/HelveticaNowDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Now Display Medium';
  src: local('Helvetica Now Display Medium'), local('Helvetica-Now-Display-Medium'),
    url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff2') format('woff2'),
    url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.woff') format('woff'),
    url('../../../public/HelveticaNowDisplay-Medium/HelveticaNowDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Now Display Extra Bold';
  src: local('Helvetica Now Display Extra Bold'), local('Helvetica-Now-Display-Extra-Bold'),
    url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff2') format('woff2'),
    url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.woff') format('woff'),
    url('../../../public/HelveticaNowDisplay-ExtraBold/HelveticaNowDisplay-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Now Display Thin';
  src: local('Helvetica Now Display Thin'), local('Helvetica-Now-Display-Thin'),
    url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff2') format('woff2'),
    url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.woff') format('woff'),
    url('../../../public/HelveticaNowDisplay-Thin/HelveticaNowDisplay-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

.backgroundCol {
  margin-top: -6vw;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.service-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #0B1465 0%, #8F4F5F 65.22%, #FFFFFF 99.09%);
  position: relative;
  overflow: hidden;
}

.service-container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8.1vw;
}

.rangeOfService {
  color: #FFFFFF;
  font-size: 3vw;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.rangeOfService2 {
  width: 55vw;
  text-align: center;
  color: #FFFFFF;
  font-size: 1.2vw;
  /* font-family: 'Helvetica Now Display Regular'; */
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin-top: -1vw;
}

.colorCircle {
  position: absolute;
  left: 2vw;
  top: 19vw;
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  background: linear-gradient(180deg, #F7714E 0%, #3343D6 100%);
}

.colorCircle2 {
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  background: linear-gradient(180deg, #F7714E 0%, #3343D6 100%);
  position: absolute;
  top: 70vw;
  left: 87vw;
}

.service-middleCard {
  position: relative;
  z-index: 10;
  margin-top: 5vw;
  display: flex;
  width: 85vw;
  height: auto;
  border-radius: 25px;
  background: linear-gradient(180deg, #0D1565 0%, #AF5D5D 100%);
}

.service-sidenav {
  width: 70vw;
}

.service-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 5vw;
}

.service-list>li {
  list-style: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  /* font-family: "Helvetica Now Display Medium"; */
  display: flex;
  text-align: center;
  gap: 10px;
}

.items {
  margin-top: 2.5vw;
  font-weight: bold;
  padding: 0.5vw 1vw;
  color: #FFFFFF;
  font-size: 1.2vw;
}

.items.selected {
  background-color: #ffffff;
  color: black;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 2vw 0vw 1.3vw 1vw;
  width: 18.5vw;
  /* padding-left: 4.5vw; */
  z-index: 10;
}

.wightCard {
  background-color: #FFFFFF;
  margin-top: 1vw;
  margin-right: 1vw;
  margin-bottom: 1vw;
  border-radius: 2vw;
  padding-right: 1vw;
  box-shadow: 0px 4px 4px 0px #00000040;
  z-index: 0;
}

.image-and-content {
  display: flex;
  margin-left: 5vw;
  margin-top: 5vw;
  /* column-gap: 5vw; */
  justify-content: space-around;
  width: 56vw;
  flex-direction: column;
}

.div1Image>img {
  width: 5vw;
  height: 5vw;
}

.div1Image {
  position: relative;
  bottom: 2vw;
  display: flex;
  gap: 6vw;
}

.div2Content {
  margin-top: -3vw;
  /* margin-left: -4vw; */
  /* padding-right: 1vw; */
  width: 94%;
}

.div1Image>h1 {
  font-size: 3vw;
  /* width: 35vw; */
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-top: 0.5vw;
}

.div2Content p {
  /* width: 40vw; */
  /* width: 38vw; */
  text-align: justify;
  text-justify: inter-word;
  word-spacing: -1px;
}

.div2Content p:last-child {
  text-align-last: left;
  /* Align the last line to the left */

}

.div2Content span {
  /* width: 28vw; */
}

.Content_Div {
  /* margin-top: 5vw; */
  height: 75vw;
  overflow: hidden;
  overflow-y: auto;
  /* border: 1px solid; */
  /* text-align: initial; */
}

.Content_Div::-webkit-scrollbar {
  background-image: linear-gradient(180deg, #F7714E 0%, #3343D6 100%);
  border-radius: 10px;
  width: .5vw;
  opacity: .5;
}

.Content_Div::-webkit-scrollbar-thumb {
  background-color: rgb(179, 154, 154);
  border-radius: 10px;
  opacity: .5;
}

.Content_Div h4 {
  font-family: "Poppins", sans-serif;
  font-size: 1.3vw;
  /* font-family: 'Helvetica Now Display Regular'; */
  /* font-family: "Helvetica Now Display Medium"; */
  /* width: 38vw; */
}

.Content_Div p {
  font-size: 1.1vw;
  letter-spacing: 0.8px;
  /* font-family: 'Helvetica Now Display Regular'; */
  font-family: "Inter", sans-serif;
  font-weight: 500;
  /* width: 37vw; */
}



.Content_div_Span {
  /* font-family: 'Helvetica Now Display Regular'; */
  font-family: "Inter", sans-serif;
  font-weight: 500;
  /* font-size: 1.1vw;
  line-height: 0.1vw; */
  font-size: 1.1vw;
  line-height: 1.3vw;
  letter-spacing: 1px;

}

.SubHeading {
  /* font-family: 'Helvetica Now Display Regular'; */
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.2vw;
}

.Span_div {
  padding: .5vw 0vw;
  /* width: 37vw; */
  text-align: justify;
  text-justify: inter-word;
  word-spacing: -2px;

}

.Span_div:last-child {
  text-align-last: left;
  /* Align the last line to the left */
  word-spacing: 0px;
}

.get-a-quote {
  width: 8.2vw;
  height: 1.5vw;
  border-radius: 30px;
  background: linear-gradient(90deg, #528EFF 0%, #F7714E 100%);
  margin-left: 45vw;
  margin-top: 5vw;
  cursor: pointer;
}

.get-a-quote>h5 {
  width: 9vw;
  font-size: 1.5vw;
  /* font-family: 'Helvetica Now Display Regular'; */
  font-family: "Helvetica Now Display Medium";
  font-weight: 600;
  position: relative;
  top: -0.9vw;
  left: 0.3vw;
  padding: .1vw;
}

.service-footer {
  margin-top: 5vw;
}

.service_icons {
  font-size: 1.5vw;
}


@media screen and (min-width:320px) and (max-width: 780px) {
  .service-container {
    display: flex;
    /* flex-direction: row; */
  }

  .service-middleCard {
    background: linear-gradient(180deg, #0D1565 0%, #AF5D5D 100%);
    width: 95vw;
    height: 215vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 2vw;
    
  }

  .wightCard {
    background-color: #FFFFFF;
    width: 90vw;
    height: 100vh;
    margin-right: 0vw;
    margin-bottom: 3vw;
  }

  .service-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 8vw;
    margin-left: -9vw;
    gap: 3vw;
  }

  .items.selected {
    background-color: #ffffff;
    color: black;
    /* padding: 2vw 1vw 0.5vw 1vw; */
    width: 25vw;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .items {
    font-size: 3vw;
  }

  .SubHeading {
    font-size: 2.5vw;
  }

  .service-sidenav {
    width: 100%;
    padding-left: 4vw;
  }

  .Content_div_Span {
    font-size: 2.2vw;
  }

  .Content_Div {
    height: 67vw;
    margin-right: 4vw;
    padding-right:5vw;
  }

  .Content_Div p {
    font-size: 2.1vw;
    /* width: 8vw; */
  }

  .Content_Div h4 {
    font-size: 3vw;
    /* width: 84vw; */
  }

  .div2Content {
    width: 80vw;
  }

  .div2Content>h1 {
    font-size: 6vw;
    /* width: 84vw; */
  }

  .div1Image {
    position: relative;
    left: 3vw;
    display: flex;
    align-items: center;
    width: 95vw;
  }

  .div1Image>img {
    width: 15vw;
    height: 16vw;
  }

  .get-a-quote {
    margin-left: 70vw;
  }

  .Span_div {
    /* width: 50vw; */
    line-height: 3vw;
  }

  .image-and-content {
    margin-left: 8vw;
  }

  .service_icons {
    font-size: 22px;
  }

  .service-container {
    margin-top: 18vw;
  }

  .rangeOfService {
    font-size: 5vw;
  }

  .rangeOfService2 {
    font-size: 3vw;
    width: 80vw;
    font-weight: 400;
  }

  .colorCircle {
    top: 36vw;
    left: 0vw;
  }

  .service-list>li {
    font-size: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23vw;
  }
  .get-a-quote>h5{
    font-size: 3vw;
    width: 20vw;
    position: relative;
    left: -1.5vw;
    top: -2vw;
  }
  .get-a-quote{
    position: relative;
    right: 5vw;
    width: 17vw;
  }
}

/* For screens above 760px */
/* Base styles and font face imports remain the same */

/* Additional media query for screens wider than 760px */
@media screen and (min-width: 760px) and (max-width: 1024px) {
  .service-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1vw;
    margin: 0;
    padding-left: 0;
    padding-right: 0;

  }

  .service-middleCard {
    width: 100vw;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-left: 0;
    margin-right: 0;
  }

  .items {
    font-size: 2vw;
    padding: 1vw;
  }

  .items.selected {
    padding: 2vw 1vw;
    width: auto;
    border-radius: 10px;
  }

  .wightCard {
    width: 90vw;
    margin: 0 auto;
  }

  .Content_Div {
    font-size: 1vw;
    padding: 1vw;
    width: auto;
    margin: 0;
  }

  .div2Content h1 {
    font-size: 2.5vw;
  }

  .Span_div {
    width: 100%;
  }

  .get-a-quote {
    margin-left: auto;
    margin-right: 2vw;
  }


}

/* Styles for larger screens (above 1024px) */
/* @media screen and (min-width: 1024px) {
  .service-list {
    display: flex;
    flex-direction: column;
  }
  .service-middleCard {
    flex-direction: row;
    height: auto;
  }
  .wightCard {
    width: 60vw;
  }
  .Content_Div {
    width: 55vw;
    font-size: 1vw;
  }
  .get-a-quote {
    margin-left: 45vw;
  }
} */
